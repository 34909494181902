import * as React from "react";
import type { HeadFC } from "gatsby";
import HomepageLayout from "../components/layouts/homepage-layout";
import TitleImage from "../components/homepage-title";
import HomepageBody from "../components/body";

import "bootstrap/dist/css/bootstrap.min.css";
const IndexPage = () => {
  return <HomepageLayout Body={<HomepageBody />} />;
};

export default IndexPage;

export const Head: HeadFC = () => (
  <>
    {/* <!-- HTML Meta Tags --> */}
    <title>Equipos Ecuestres Caminadores de caballos</title>
    <meta
      name="description"
      content="Equipos ecuestres para el entrenamiento y cuidado de caballos, hecho con ingeniería colombiana. Vendemos Caminadores de caballos."
    />

    {/* <!-- Google / Search Engine Tags --> */}
    <meta itemProp="name" content="Equipos Ecuestres" />
    <meta
      itemProp="description"
      content="Equipos ecuestres para el entrenamiento y cuidado de caballos, hecho con ingeniería colombiana."
    />
    <meta
      itemProp="image"
      content="https://lh3.googleusercontent.com/p/AF1QipOPcI28FSAyVLD4SZTyxLg92u9ij9TuMToPBfIF=s1360-w1360-h1020"
    />

    {/* <!-- Facebook Meta Tags --> */}
    <meta property="og:url" content="https://equiposecuestres.com" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Equipos Ecuestres" />
    <meta
      property="og:description"
      content="Equipos ecuestres para el entrenamiento y cuidado de caballos, hecho con ingeniería colombiana."
    />
    <meta
      property="og:image"
      content="https://lh3.googleusercontent.com/p/AF1QipOPcI28FSAyVLD4SZTyxLg92u9ij9TuMToPBfIF=s1360-w1360-h1020"
    />

    {/* <!-- Twitter Meta Tags --> */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Equipos Ecuestres" />
    <meta
      name="twitter:description"
      content="Equipos ecuestres para el entrenamiento y cuidado de caballos, hecho con ingeniería colombiana."
    />
    <meta
      name="twitter:image"
      content="https://lh3.googleusercontent.com/p/AF1QipOPcI28FSAyVLD4SZTyxLg92u9ij9TuMToPBfIF=s1360-w1360-h1020"
    />

    <script type="application/ld+json">
      {`
    {
      "@context": "https://schema.org",
      "@type": "Store",
      "url": "https://equiposecuestres.com",
      "image": "/logo.png",
      "name": "Equipos Ecuestres",
      "telephone": "+573133776456",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+573133776456",
        "email": "contactoequiposecuestres@gmail.com",
        "contactType": "Sales"
      }
    }
  `}
    </script>
  </>
);
