import React from "react";
import Carrousel from "./complements/products/feature-item/featured-item";
import TitleImage from "./homepage-title";
import HeaterItem from "./complements/products/heater";
import WalkerItem from "./complements/products/walker";
import ControlPanel from "./complements/products/feature-item/control-panel";
import DivisionPanel from "./complements/products/feature-item/division-panel";
import CarrouselTitleDescription from "./complements/products/feature-item/title-description";

function HomepageBody() {
  return (
    <>
      <TitleImage />
      <br />
      <div className="bg-horseSunset bg-cover bg-fixed sm:bg-horseField  sm:bg-bottom sm:bg-no-repeat">
        <div className="bg-black bg-opacity-75 ">
          <div className="container ">
            <div className="flex flex-col gap-4   text-gray-50">
              <CarrouselTitleDescription />
              <Carrousel />
              <ControlPanel />
              <DivisionPanel />
              <WalkerItem />
              <HeaterItem />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default HomepageBody;
