import React from "react";
import ProductDetailLayout from "../../layouts/product-detail-layout";
import FadingList from "../../utilities/fading-list";
//@ts-ignore
import solarioImg from "./../../../images/solario.png";
function HeaterItem() {
  return (
    <div className="text-gray-50 justify-center">
      <ProductDetailLayout
        image={
          <img
            className="justify-center  rounded"
            alt="hero"
            src={solarioImg}
          />
        }
        title={<p className="text-center">Solarios</p>}
        description={
            <FadingList
              listItems={[
                "Diseñados para mejorar la condición física de los caballos, especialmente luego de jornadas exigentes.",
                "Altura regulable.",
                "Tiempos, intensidad y elevación programables",
              ]}
            />
        }
      />
      <br />
    </div>
  );
}
export default HeaterItem;
