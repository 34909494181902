import React, { useContext } from "react";
import { CarrouselContext } from "../../../context/carrousel-index";
//@ts-ignore
import p4125 from "./../../../../images/client_pictures/pistas/p-4-125.png";
//@ts-ignore
import p415 from "./../../../../images/client_pictures/pistas/p-4-15.png";
//@ts-ignore
import p515 from "./../../../../images/client_pictures/pistas/p-5-15.png";
//@ts-ignore
import p615 from "./../../../../images/client_pictures/pistas/p-6-15.png";
//@ts-ignore
import p621 from "./../../../../images/client_pictures/pistas/p-6-21.png";
//@ts-ignore
import p821 from "./../../../../images/client_pictures/pistas/p-8-21.png";
//@ts-ignore
import p823 from "./../../../../images/client_pictures/pistas/p-8-23.png";

function ConfigurationSquare({
  index,
  currentIndex,
}: {
  index: number;
  currentIndex: number;
}) {
  const classConfigs = [
    //0 p4125
    {
      selected:
        "border-4 aspect-square bg-p4125 bg-no-repeat bg-contain bg-center border-amber-400 rounded",
      unselected: "aspect-square bg-p4125 bg-no-repeat bg-contain bg-center",
    },
    //1 p415
    {
      selected:
        "border-4 aspect-square bg-p415 bg-no-repeat bg-contain bg-center border-amber-400 rounded",
      unselected: "aspect-square bg-p415 bg-no-repeat bg-contain bg-center",
    },
    //2 p515
    {
      selected:
        "border-4 aspect-square bg-p515 bg-no-repeat bg-contain bg-center border-amber-400 rounded",
      unselected: "aspect-square bg-p515 bg-no-repeat bg-contain bg-center",
    },
    //3 p615
    {
      selected:
        "border-4 aspect-square bg-p615 bg-no-repeat bg-contain bg-center border-amber-400 rounded",
      unselected: "aspect-square bg-p615 bg-no-repeat bg-contain bg-center",
    },
    //4 p621
    {
      selected:
        "border-4 aspect-square bg-p621 bg-no-repeat bg-contain bg-center border-amber-400 rounded",
      unselected: "aspect-square bg-p621 bg-no-repeat bg-contain bg-center",
    },
    //5 p821
    {
      selected:
        "border-4 aspect-square bg-p821 bg-no-repeat bg-contain bg-center border-amber-400 rounded",
      unselected: "aspect-square bg-p821 bg-no-repeat bg-contain bg-center",
    },
    //6 p823
    {
      selected:
        "border-4 aspect-square bg-p823 bg-no-repeat bg-contain bg-center border-amber-400 rounded",
      unselected: "aspect-square bg-p823 bg-no-repeat bg-contain bg-center",
    },
  ];
  return (
    <div
      className={
        currentIndex != index
          ? classConfigs[index].unselected
          : classConfigs[index].selected
      }
    />
  );
}
function ConfigurationDiagram() {
  const { index } = useContext(CarrouselContext);
  return (
    
      <div className="grid grid-rows-2 bg-white object-contain rounded">
        <div className="grid grid-cols-4">
          <ConfigurationSquare currentIndex={index} index={0} />
          <ConfigurationSquare currentIndex={index} index={1} />
          <ConfigurationSquare currentIndex={index} index={2} />
          <ConfigurationSquare currentIndex={index} index={3} />
        </div>
        <div className="grid grid-cols-3">
          <ConfigurationSquare currentIndex={index} index={4} />
          <ConfigurationSquare currentIndex={index} index={5} />
          <ConfigurationSquare currentIndex={index} index={6} />
        </div>
      </div>
  );
}
export default ConfigurationDiagram;
