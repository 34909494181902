import React from "react";
import ProductDetailLayout from "../../../layouts/product-detail-layout";
import FadeIn from "../../../utilities/fade-in";
import FadingList from "../../../utilities/fading-list";

//@ts-ignore
import control3 from "./../../../../images/client_pictures/controles/Controles 3.jpg";
function ControlPanel() {
  return (
    <ProductDetailLayout
      title={<p className="text-center">Panel de Control</p>}
      isReversed={true}
      image={
        <div className="flex flex-row justify-center">
          <FadeIn>
            <img className="rounded" src={control3} />
          </FadeIn>
        </div>
      }
      description={
        <FadingList
          description="Panel de control con pantalla táctil que permite tanto operación manual como operación automática con rutinas de ejercicios programables."
          listItems={[
            "Pantalla táctil de 7 pulgadas.",
            "Memoria con capacidad para 10 rutinas de ejercicios automáticas, cada una con 7 pasos donde se juega con velocidad, tiempo y sentido de giro.",
            "Incluye manual de operación del panel de control.",
          ]}
        />
      }
    />
  );
}
export default ControlPanel;
