import React from "react";
import FadeIn from "../../../utilities/fade-in";

function CarrouselTitleDescription() {
  return (
    <div className="flex flex-col gap-4 overflow-hidden  ">
      <div className="flex flex-row justify-center">
        <FadeIn>
          <h1>Carruseles para caballos</h1>
        </FadeIn>
      </div>
      <div className="flex flex-col text-xl text-center ">
        <br />
        <FadeIn>
          <p>
            Ofrecemos pistas de entrenamiento con capacidades desde 4 hasta 8
            caballos, según diámetros estándar de 12.5, 15, 21 y 23 metros.
          </p>
        </FadeIn>
      </div>
    </div>
  );
}
export default CarrouselTitleDescription;
