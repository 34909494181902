import React from "react";
import NavBar from "../navbar";
import TitleImage from "../homepage-title";
import Footer from "../footer";

type HomePageElements = {
  // Header: JSX.Element;
  // Footer: JSX.Element;
  Body: JSX.Element;
};
function HomepageLayout(children: HomePageElements) {
  return (
    <div className="flex-auto flex-col bg-logoColor">
      <header className="sticky top-0 z-50">{<NavBar/>}</header>
      {children.Body}
      {<Footer/>}
    </div>
  );
}

export default HomepageLayout