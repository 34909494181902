import React from "react";
import ProductDetailLayout from "../../../layouts/product-detail-layout";
import FadeIn from "../../../utilities/fade-in";
import FadingList from "../../../utilities/fading-list";

//@ts-ignore
import divisionPanel from "./../../../../images/client_pictures/Panel división.jpg";
function DivisionPanel() {
  return (
    <ProductDetailLayout
      title={<p className="text-center">Paneles de división</p>}
      image={
        <div className="flex flex-row justify-center">
          <FadeIn>
            <img className="rounded " src={divisionPanel} />
          </FadeIn>
        </div>
      }
      description={
        <>
          <FadingList
            listItems={[
              "Paneles en tubo de aluminio anodizado, livianos y seguros ante el posible contacto con los caballos.",
              "Pulsos eléctricos, accionados a voluntad desde el panel de control.",
              "Posición ajustable.",
              "Sistema de riego en uno de los paneles (opcional).",
            ]}
          />
        </>
      }
    />
  );
}
export default DivisionPanel;
