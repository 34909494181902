import React from "react";
//@ts-ignore
import coverPhoto from "./../images/cover.jpg";

function TitleImage() {
  return (
    <div>
      {/* <CoverCarousel /> */}
      <div
        className="relative bg-no-repeat bg-cover"
        style={{
          backgroundImage: `url(${coverPhoto})`,
          backgroundPosition: "50%",
          height: "500px",
        }}
      >
        <div
          className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed drop-shadow"
          style={{
            textShadow: "-1px 1px 20px rgba(0, 0, 0, 1)",
          }}
        >
          {/* <!-- Background image --> */}
          <div className="flex justify-center items-center h-full">
            <div className="text-center text-white px-6">
              <h1 className="text-5xl font-bold mt-0 mb-6">
                Equipos Ecuestres
              </h1>
              <h3 className="text-3xl font-bold mb-8">Ingeniería Colombiana</h3>
              <button type="button">
                <a
                  href="/contact/"
                  className="inline-block px-6 py-2.5 border-2 no-underline border-white text-white  font-bold text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                  style={{
                    textShadow: "-1px 1px 20px rgba(0, 0, 0, 1)",
                  }}
                >
                  Contactanos
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TitleImage;
