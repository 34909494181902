import React, { useState } from "react";
import CarrouselProvider from "../../../context/carrousel-index";
import FadeIn from "../../../utilities/fade-in";
import ConfigurationCarrousel from "./configuration-carousel";
import ConfigurationDiagram from "./configurations";

function Carrousel() {
  return (
    <CarrouselProvider>
      <div className="grid grid-col-1 md:grid-cols-2 gap-4">
        <div className="container">
          <FadeIn>
            <ConfigurationCarrousel />
          </FadeIn>
        </div>
        <div className="container">
          <FadeIn>
            <ConfigurationDiagram />
          </FadeIn>
        </div>
      </div>
    </CarrouselProvider>
  );
}
export default Carrousel;
