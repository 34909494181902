import React from "react";
import FadeIn from "./fade-in";
type FadingListProps = {
  title?: string;
  description?: string;
  listItems: string[];
};
function FadingList(props: FadingListProps) {
  return (
    <>
      <FadeIn>
        <h2 className="text-gray-50 text-2xl title-font font-medium ">
          {props.title}
        </h2>
      </FadeIn>
      <div className="leading-relaxed text-base">
        <FadeIn>
          <div>{props.description}</div>
        </FadeIn>
        <br />
        <ul className="list-disc">
          {props.listItems.map((item) => {
            return (
              <FadeIn key={item}>
                <li className="list-item ">{item}</li>
              </FadeIn>
            );
          })}
        </ul>
      </div>
    </>
  );
}
export default FadingList;
