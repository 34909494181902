import React, { useContext } from "react";
import { Carousel } from "react-bootstrap";
import { CarrouselContext } from "../../../context/carrousel-index";
//@ts-ignore
import CarouselComp from "../complements/uncontrolled-carousel";
//@ts-ignore
import controlPanelImg from "./../../images/client_pictures/carrusel/Carrusel 4 A 15.jpg";
interface CarouselProps {
  carouselProp: {
    imgPath: string;
    alt: string | undefined;
    captionTitle?: string | undefined;
    captionText?: string | JSX.Element | undefined;
  }[];
}
function FeaturedItemCarousel(props: CarouselProps) {
  const { setIndex } = useContext(CarrouselContext);
  return (
    <Carousel
      className="bg-logoColor overflow-hidden rounded"
      onSelect={(eventKey) => {
        setIndex(eventKey);
      }}
      nextIcon={
        <span
          aria-hidden="true"
          className="carousel-control-next-icon filter invert mb-32"
        />
      }
      prevIcon={
        <span
          aria-hidden="true"
          className="carousel-control-prev-icon filter invert mb-32"
        />
      }
    >
      {props.carouselProp.map((prop) => {
        return (
          <Carousel.Item key={prop.alt}>
            <img
              className="d-block w-100 pb-32 aspect-square"
              src={prop.imgPath}
              alt={prop.alt}
            />

            <Carousel.Caption>
              <h3>{prop.captionTitle}</h3>
              <div className="flex flex-row justify-center whitespace-nowrap ">
                {prop.captionText}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}
export default FeaturedItemCarousel;
