import React from "react";
import ProductDetailLayout from "../../layouts/product-detail-layout";
import FadingList from "../../utilities/fading-list";
//@ts-ignore
import caminadoraImg from "./../../../images/caminadora.png";
function WalkerItem() {
  return (

    <ProductDetailLayout
    isReversed={true}
      title={<p className="text-center">Bandas Caminadoras</p>}
      image={
        <img
          className="rounded "
          alt="hero"
          src={caminadoraImg}
        />
      }
      description={
        <FadingList
          listItems={[
            "Funcionamiento con velocidad e inclinación ajustables.",
            "Rutinas de ejercicios programables mediante pantalla táctil.",
            "Sensores fotoeléctricos de seguridad.",
            "Agua (opcional).",
          ]}
        />
      }
    />
  );
}
export default WalkerItem;
