import React from "react";
import FadeIn from "../utilities/fade-in";
type ProductDetailProps = {
  title: JSX.Element;
  image: JSX.Element;
  description: string | JSX.Element;
  isReversed?: boolean;
};
function ProductDetailLayout(props: ProductDetailProps) {
  const title = (
    <FadeIn>
      <h2 className="text-gray-50 text-4xl title-font font-medium">
        {props.title}
      </h2>
    </FadeIn>
  );
  const image = <FadeIn>{props.image}</FadeIn>;
  const description = (
    <FadeIn>
      <div className="leading-relaxed text-base">{props.description}</div>
    </FadeIn>
  );
  return (
    <div className="">
      {title}
      {props.isReversed ? (
        <div className={"grid grid-cols-1 sm:grid sm:grid-cols-2 gap-3"}>
          <div className="sm:order-last ">{image}</div>
          <div className="sm:order-first ">{description}</div>
        </div>
      ) : (
        <div className={"grid grid-cols-1 sm:grid-cols-2"}>
          <div className="order-first ">{image}</div>
          <div className="order-last">{description}</div>
        </div>
      )}
    </div>
  );
}
export default ProductDetailLayout;
