import React from "react";

//@ts-ignore
import carrousel1 from "./../../../../images/client_pictures/carrusel/Carrusel 4 A 15.jpg";
//@ts-ignore
import carrousel2 from "./../../../../images/client_pictures/carrusel/Carrusel 4 A 12.5.jpg";
//@ts-ignore
import carrousel3 from "./../../../../images/client_pictures/carrusel/Carrusel 5 A 15.jpg";
//@ts-ignore
import carrousel4 from "./../../../../images/client_pictures/carrusel/Carrusel 6 A 15.jpg";
//@ts-ignore
import carrousel5 from "./../../../../images/client_pictures/carrusel/Carrusel 6 A 21.jpg";
//@ts-ignore
import carrousel6 from "./../../../../images/client_pictures/carrusel/Carrusel 6 A 23.jpg";
//@ts-ignore
import carrousel7 from "./../../../../images/client_pictures/carrusel/Carrusel 8 A 21.jpg";
import FeaturedItemCarousel from "./feature-item-img";
function ConfigurationCarrousel() {
  return (
    <FeaturedItemCarousel
      carouselProp={[
        {
          imgPath: carrousel2,
          alt: "Carrusel 4 A 12.5",
          captionText: (
            <ul className="list-disc text-start">
              <li>Número de caballos: 4</li>
              <li>Diámetro: 12.5 metros</li>
            </ul>
          ),
        },
        {
          imgPath: carrousel1,
          alt: "Carrusel 4 A 15",
          captionText: (
            <ul className="list-disc text-start">
              <li>Número de caballos: 4</li>
              <li>Diámetro: 15 metros</li>
            </ul>
          ),
        },
        {
          imgPath: carrousel3,
          alt: "Carrusel 5 A 15",
          captionText: (
            <ul className="list-disc text-start">
              <li>Número de caballos: 5</li>
              <li>Diámetro: 15 metros</li>
            </ul>
          ),
        },
        {
          imgPath: carrousel4,
          alt: "Carrusel 6 A 15",
          captionText: (
            <ul className="list-disc text-start">
              <li>Número de caballos: 6</li>
              <li>Diámetro: 15 metros</li>
            </ul>
          ),
        },
        {
          imgPath: carrousel5,
          alt: "Carrusel 6 A 21",
          captionText: (
            <ul className="list-disc text-start">
              <li>Número de caballos: 6</li>
              <li>Diámetro: 21 metros</li>
            </ul>
          ),
        },
        {
          imgPath: carrousel7,
          alt: "Carrusel 8 A 21",
          captionText: (
            <ul className="list-disc">
              <li>Número de caballos: 8</li>
              <li>Diámetro: 21 metros</li>
            </ul>
          ),
        },
        {
          imgPath: carrousel6,
          alt: "Carrusel 8 A 23",
          captionText: (
            <ul className="list-disc text-start">
              <li>Número de caballos: 8</li>
              <li>Diámetro: 23 metros</li>
            </ul>
          ),
        },
      ]}
    />
  );
}
export default ConfigurationCarrousel;
