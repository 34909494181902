import React, { useState } from "react";

type CarrouselContext = {
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
};

export const CarrouselContext = React.createContext<CarrouselContext>(
  {} as CarrouselContext
);
function CarrouselProvider({ children }: any) {
  const [index, setIndex] = useState(0);
  return (
    <CarrouselContext.Provider value={{ index, setIndex }}>
      {children}
    </CarrouselContext.Provider>
  );
}
export default CarrouselProvider;
